import { ComponentProps, useContext } from 'react';
import { useRouter } from 'next/router';
import { i18n as I18n } from 'utils/i18n';

import QuickSearchForm from 'components/forms/quickSearch';
import { SearchContext } from 'state/search';

type Props = ComponentProps<typeof QuickSearchForm>;

type Values = Props['initialValues'];
type OnSubmit = Props['onSubmit'];

const QuickSearch: typeof QuickSearchForm = ({
  onSubmit: onSubmitProp = () => null,
  ...props
}) => {
  const { actions, filters } = useContext(SearchContext);

  const initialValues: Values = {
    location: filters.location,
    distance: filters.distance === null ? '' : String(filters.distance),
    geolocation: filters.latLng,
    roomTypes: filters.roomTypes,
  };

  const router = useRouter();
  const { asPath: currentRoute = '' } = router;

  const onSubmit: OnSubmit = (values, helpers) => {
    const { location, geolocation, distance, roomTypes } = values;
    const { setSubmitting } = helpers;

    if (!currentRoute.match(I18n.t('navigation.search.url'))) {
      router.push(I18n.t('navigation.search.url'));
    }

    actions.setQuickSearch({
      location,
      latLng: geolocation || null,
      distance: distance ? Number(distance) : null,
      roomTypes,
    });

    onSubmitProp(values, helpers);
    setSubmitting(false);
  };

  return (
    <QuickSearchForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      {...props}
    />
  );
};

export default QuickSearch;
