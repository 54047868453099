import { useState, useCallback } from 'react';

import useApiRequest from 'hooks/useApiRequest';
import useAsync from 'hooks/useAsync';

type SearchLocation = {
  name: string;
  city: string;
  term: string;
};

type Hit = SearchLocation & {
  id: number;
};

type Bucket = SearchLocation & {
  count: number;
};

type Aggregate = {
  name: string;
  buckets: Array<Bucket>;
};

type Locations = {
  hits: Array<Hit>;
  aggregates?: Array<Aggregate>;
};

const useLocations = (location: string) => {
  const [locations, setLocations] = useState<Locations>({
    hits: [],
    aggregates: [],
  });

  const apiRequest = useApiRequest();

  const requestValid = !!location && location.length > 2;

  const clearData = useCallback(async () => {
    setLocations({
      hits: [],
      aggregates: [],
    });
  }, []);

  const fetchData = useCallback(async () => {
    const { data } = await apiRequest.get('/v1/venues/location', {
      params: { q: location },
    });

    setLocations(data);
  }, [location, apiRequest]);

  const { loading, error, refresh } = useAsync(
    requestValid ? fetchData : clearData,
    requestValid
  );

  return { data: locations, loading, error, refresh };
};

export default useLocations;
