import { FC, ComponentProps, ReactNode } from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';

import { i18n as I18n } from 'utils/i18n';

interface Props extends ComponentProps<typeof Modal> {
  onClose: () => void;
  headerLabel?: string;
  header?: ReactNode;
  size?: 'standard' | 'small' | 'xsmall';
  forceActionClickToClose?: boolean;
  noPadding?: boolean;
  showCloseIcon?: boolean;
  primaryAction?: ReactNode;
  secondaryAction?: ReactNode;
  showCloseButton?: boolean;
  closeText?: string;
}

const ModalDialog: FC<Props> = ({
  size = 'standard',
  headerLabel = null,
  header = null,
  children,
  isOpen = false,
  onClose,
  showCloseIcon = true,
  noPadding = false,
  forceActionClickToClose = false,
  primaryAction = null,
  secondaryAction = null,
  showCloseButton = false,
  closeText = I18n.t('modal.close'),
  type: modalType,
  className: classNameProp,
  aria = {},
  ...props
}) => {
  const className = classNames(
    'modal',
    {
      'modal--small': size === 'small',
      'modal--xsmall': size === 'xsmall',
      'model--has-footer': !!primaryAction || !!secondaryAction,
      'modal--no-padding': noPadding,
      [`${modalType}__modal`]: !!modalType,
    },
    classNameProp
  );

  const triggerCloseModal = e => {
    e.preventDefault();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={triggerCloseModal}
      className={className}
      overlayClassName="modal__overlay"
      shouldCloseOnOverlayClick={!forceActionClickToClose}
      ariaHideApp={false}
      aria={{
        ...(!header && !!headerLabel ? { labelledby: 'modal-title' } : {}),
        ...aria,
      }}
      {...props}
    >
      <div data-testid="modalDialog">
        {!forceActionClickToClose && showCloseIcon && (
          <a
            href="#"
            className="modal__close modal__close--button"
            onClick={triggerCloseModal}
          >
            {I18n.t('modal.close')}
            <span />
          </a>
        )}

        {!headerLabel && header}

        {!header && !!headerLabel && (
          <div className="modal__title">
            <h2 id="modal-title" className="modal__title-text">
              {headerLabel}
            </h2>
          </div>
        )}

        <div className="modal__inner" data-cy="modalContent">
          {children}
        </div>

        {(primaryAction || secondaryAction) && (
          <div className="modal__footer" data-testid="modalFooter">
            {!!primaryAction && (
              <div className="modal__footer-item">{primaryAction}</div>
            )}
            {!!secondaryAction && (
              <div className="modal__footer-item">{secondaryAction}</div>
            )}
          </div>
        )}

        {showCloseButton && (
          <button
            onClick={onClose}
            className="button button--text button--center"
            data-testid="closeButton"
          >
            {closeText}
          </button>
        )}
      </div>
    </Modal>
  );
};

export default ModalDialog;
