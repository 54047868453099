import { FC, useContext, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import classNames from 'classnames';

import useIsMobile from '../../../hooks/useIsMobile';
import useOutsideClick from '../../../hooks/useOutsideClick';

import { MobileHeaderContext } from '../../../state/mobileHeader';

import { roomTypes } from '../../../constants/fieldData';

import Badge from '../../badge';

import { i18n as I18n } from '../../../utils/i18n';

import RoomType from './roomType';

type Option = typeof roomTypes[number]['category'];

interface Props {
  name?: string;
  value?: Option[];
  setValue?: (value: Option[]) => void;
  clearValue?: () => void;
  className?: string;
}

const RoomTypesField: FC<Props> = ({
  name = 'room_types[]',
  value = [],
  setValue = () => null,
  clearValue = () => setValue([]),
  className: classNameProp,
}) => {
  const { setRoomTypesOpen } = useContext(MobileHeaderContext);

  const toggleSelected = (roomType: Option) =>
    setValue(
      value.includes(roomType)
        ? value.filter(selected => selected !== roomType)
        : [...value, roomType]
    );

  const [dropdownActive, setDropdownActive] = useState<boolean>(false);
  const [placeholderTextUpdated, setPlaceholderTextUpdated] = useState<boolean>(
    false
  );

  const isMobile = useIsMobile();

  useEffect(() => {
    setRoomTypesOpen(dropdownActive);
  }, [setRoomTypesOpen, dropdownActive]);

  const className = classNames('field', classNameProp);

  const buttonClasses = classNames(
    'button',
    'button--block',
    'button--dropdown',
    'button--room-types',
    {
      'button--dropdown-active': dropdownActive,
    }
  );

  const toggleDropdownActive = () => {
    setDropdownActive(active => !active);
  };

  const fieldRef = useRef();

  const handleButtonClick = event => {
    event.preventDefault();
    toggleDropdownActive();
  };

  const handleRoomTypesApplyBtn = event => {
    event.preventDefault();
    toggleDropdownActive();
    setPlaceholderTextUpdated(true);
  };

  const renderButtonText = () => {
    // if multiple room types are selected, we just want to show
    // a count of how many
    if (value.length > 1) {
      return (
        <>
          Room types
          <Badge count={value.length} />
        </>
      );
    }

    // if only one room type selected, just show the name of the room type
    if (value.length === 1) {
      const selectedType = roomTypes.find(type => type.category === value[0]);

      return selectedType.label;
    }

    if (value.length === 0 && placeholderTextUpdated)
      return I18n.t('room_type_field.all_rooms');

    // otherwise if nothing selected, show the default text
    return isMobile
      ? I18n.t('room_type_field.select_room_types.mobile')
      : I18n.t('room_type_field.select_room_types.desktop');
  };

  useOutsideClick(fieldRef, () => setDropdownActive(false));

  return (
    <div className={className} data-cy="roomTypesField" ref={fieldRef}>
      <button
        type="button"
        className={buttonClasses}
        onClick={handleButtonClick}
        data-testid="roomTypeButton"
      >
        {renderButtonText()}
      </button>

      {!dropdownActive &&
        value.map(roomType => (
          /*
            if the window is closed, we need to render hidden form elements
            as the checkboxes are removed from the DOM and do not get submitted
            as form values
          */
          <input
            key={roomType}
            type="hidden"
            name={name}
            value={roomType}
            data-testid="roomTypesHidden"
          />
        ))}

      {dropdownActive && (
        <div className="room-types">
          <h1 className="room-types__title">Select Room Types</h1>
          <div className="room-types__inner">
            {roomTypes.map(({ machineName, label, clinical, category }) => (
              <RoomType
                key={machineName}
                label={label}
                id={label}
                name={name}
                clinical={clinical}
                category={category}
                icon={machineName}
                checked={value.includes(category)}
                onChange={() => toggleSelected(category)}
                data-testid="roomTypeOption"
              />
            ))}
          </div>

          <p className="room-types__link">
            <Link
              href="#alternative-room-types"
              to={{
                pathname: '/',
                hash: '#alternative-room-types',
              }}
            >
              <a href="/#room-types" onClick={() => setDropdownActive(false)}>
                {I18n.t('shared.room_types_information')}
                <img
                  src="/images/icons/homepage/navigation/chevron.svg"
                  alt=""
                />
              </a>
            </Link>
          </p>

          <div className="room-types__submit">
            <a
              href="#"
              onClick={() => clearValue()}
              data-testid="clearAllButton"
            >
              {I18n.t('shared.clear_all')}
            </a>
            <button
              onClick={handleRoomTypesApplyBtn}
              className="button button--secondary"
              data-testid="apply"
            >
              {I18n.t('apply')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RoomTypesField;
