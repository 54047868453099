import { validateYupSchema, yupToFormErrors } from 'formik';

export const createGetValidator = schema => (context = {}) => (values, field) =>
  Promise.resolve(
    field
      ? schema.validateAt(field, values, { context })
      : validateYupSchema(values, schema, false, context)
  )
    .then(() => ({}))
    .catch(error =>
      error.name === 'ValidationError'
        ? yupToFormErrors(error)
        : Promise.reject(error)
    );
