import { FC } from 'react';
import classNames from 'classnames';
import Link from 'next/link';

import { i18n as I18n } from 'utils/i18n';

interface FooterListProps {
  title: string;
}

const FooterList: FC<FooterListProps> = ({ title, children }) => (
  <div className="footer__list">
    <div className="footer__title">{title}</div>
    <nav>
      <ul>{children}</ul>
    </nav>
  </div>
);

interface FooterItemProps {
  text: string;
  href: string;
  image?: string;
  external?: boolean;
  newWindow?: boolean;
}

const FooterItem: FC<FooterItemProps> = ({
  text,
  href,
  image,
  external = false,
  newWindow = false,
}) => {
  const classes = classNames({
    // eslint-disable-next-line camelcase
    footer__list_wrap: !!image,
  });

  const props = newWindow ? { target: '_blank', rel: 'noreferrer' } : {};

  return (
    <li className={classes}>
      {image && <img src={image} alt={text} />}
      {!external && (
        <Link href={href}>
          <a>{text}</a>
        </Link>
      )}
      {external && (
        <a {...props} href={href}>
          {text}
        </a>
      )}
    </li>
  );
};

interface Props {
  copyrightYear?: number;
}

const Footer: FC<Props> = ({ copyrightYear = new Date().getFullYear() }) => (
  <div className="footer" id="footer">
    <div className="footer__links">
      <FooterList title={I18n.t('footer.links.nhs.title')}>
        <FooterItem
          text={I18n.t('footer.links.nhs.about_us')}
          href={I18n.t('navigation.about.url')}
        />
        <FooterItem
          text={I18n.t('footer.links.nhs.sign_up_to_our_newsletter')}
          href="https://psevents.property.nhs.uk/openspace-signup-form/"
          external
          newWindow
        />
      </FooterList>

      <FooterList title={I18n.t('footer.links.support.title')}>
        <FooterItem
          text={I18n.t('footer.links.support.help_and_faqs')}
          href={I18n.t('navigation.help.url')}
        />
        <FooterItem
          text={I18n.t('footer.links.support.covid')}
          href="https://www.property.nhs.uk/occupier-hub/how-we-can-support-you-during-covid-19/?utm_source=openspace&utm_medium=website&utm_campaign=2009_openspace_covid19"
          external
          newWindow
        />
      </FooterList>

      <FooterList title={I18n.t('footer.links.legal.title')}>
        <FooterItem
          text={I18n.t('footer.links.legal.privacy_policy')}
          href={I18n.t('navigation.privacy_policy.url')}
        />
        <FooterItem
          text={I18n.t('footer.links.legal.terms')}
          href={I18n.t('navigation.website_terms.url')}
        />
        <FooterItem
          text={I18n.t('footer.links.legal.disclaimer')}
          href={I18n.t('navigation.disclaimer.url')}
        />
      </FooterList>

      <div className="footer__list">
        <div className="footer__text">{I18n.t('footer.text')}</div>
        <nav>
          <ul>
            <FooterItem
              text={I18n.t('shared.customer_service_telephone')}
              href={I18n.t('shared.customer_service_telephone_href')}
              external
              image="/images/icons/homepage/footer/inquiry.svg"
            />
            <FooterItem
              text="customer.service@property.nhs.uk"
              href="mailto:customer.service@property.nhs.uk"
              external
              image="/images/icons/homepage/footer/email.svg"
            />
          </ul>
        </nav>
      </div>
    </div>

    <hr className="footer__break-line" />

    <div className="footer__bottom">
      <p className="footer__social">
        {I18n.t('footer.social.title')}
        <a
          href="https://www.linkedin.com/company/3071395"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="footer__linkedin-icon"
            src="/images/icons/homepage/footer/linkedin.png"
            alt="LinkedIn"
          />
        </a>
        <a
          href="https://www.instagram.com/nhsopenspace"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="footer__instagram-icon"
            src="/images/icons/homepage/footer/instagram.svg"
            alt="Instagram"
          />
        </a>
        <a
          href="https://twitter.com/nhsproperty?lang=en"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="footer__twitter-icon"
            src="/images/icons/homepage/footer/twitter.svg"
            alt="Twitter"
          />
        </a>
      </p>
      <p className="footer__copyright">
        &copy; {copyrightYear} {I18n.t('footer.copyright_owner')}
        <span>{I18n.t('footer.delivery')}</span>
      </p>
    </div>
  </div>
);

export default Footer;
