export const distances = {
  'Exact Location': 0,
  'Within \u00bd mile': 0.5,
  'Within 1 mile': 1,
  'Within 3 miles': 3,
  'Within 5 miles': 5,
  'Within 10 miles': 10,
  'Within 20 miles': 20,
  'Within 30 miles': 30,
  'Within 50 miles': 50,
};
