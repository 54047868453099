import { FC, HTMLProps } from 'react';
import { i18n as I18n } from '../../../utils/i18n';

interface Props extends Omit<HTMLProps<HTMLButtonElement>, 'onClick'> {
  name: string;
  city?: string;
  count?: number;
  onClick: (term: string) => void;
}

const formatAggregateWithCount = (name: string, count?: number = 0): string =>
  count === 0
    ? name
    : I18n.t('location_field.location_aggregate', { name, count });

const Location: FC<Props> = ({
  name,
  city,
  count = 0,
  onClick,
  className = 'location__search-result__button',
  role = 'option',
  ...props
}) => (
  <button
    className={className}
    role={role}
    type="button"
    {...props}
    onClick={onClick}
  >
    {city ? (
      <>
        {name}
        <span className="location__search-result__city">
          , {formatAggregateWithCount(city, count)}
        </span>
      </>
    ) : (
      formatAggregateWithCount(name, count)
    )}
  </button>
);

export default Location;
