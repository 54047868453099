import * as yup from 'yup';

import { createGetValidator } from 'utils/validation';
import { distances } from 'constants/distances';
import { roomTypes } from 'constants/fieldData';
import { i18n as I18n } from 'utils/i18n';

const schema = yup.object({
  location: yup
    .string()
    .label(I18n.t('venue_search_form.fields.location'))
    .when(
      ['distance', 'geolocation', 'roomTypes'],
      (distance = null, geolocation = null, roomTypes = null, schema) => {
        if (geolocation !== null) return schema.transform(() => '');
        if (roomTypes && roomTypes.length === 0)
          return schema.required(
            I18n.t(
              'venue_search_form.validation.location_or_room_types_required'
            )
          );
        return distance === null
          ? schema
          : schema.required(
              I18n.t('venue_search_form.validation.location_required')
            );
      }
    ),
  distance: yup
    .number()
    .label(I18n.t('venue_search_form.fields.distance'))
    .nullable()
    .oneOf([null, ...Object.values(distances)])
    .transform((value, original) => (original === '' ? null : value)),
  geolocation: yup
    .object({ lat: yup.number().required(), lng: yup.number().required() })
    .noUnknown()
    .label(I18n.t('venue_search_form.fields.geolocation'))
    .nullable(),
  roomTypes: yup
    .array()
    .of(
      yup
        .string()
        .label(I18n.t('venue_search_form.fields.room_type'))
        .oneOf(roomTypes.map(({ category }) => category))
    )
    .default(null)
    .nullable(),
});

export default schema;

export const getValidator = createGetValidator(schema);
