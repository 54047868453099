import { FC, useState, useEffect, useContext } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import moment from 'moment';

import { pageExemptFromConditionAcceptance } from 'utils/acceptConditions.js';
import useApiRequest from '../../hooks/useApiRequest';
import useDataLayer from '../../hooks/useDataLayer';

import { AuthContext } from '../../state/auth';
import { BasketContext } from '../../state/basket';
import { SearchContext } from '../../state/search';

import BasketExpiryModal from '../basketExpiryModal';
import AcceptConditionsModal from '../acceptConditionsModal';
import HeaderNav from '../headerNav';
import HeaderMobileNav from '../headerMobileNav';
import Footer from '../footer';
import NoScriptWarning from '../noScriptWarning';
import OutdatedBrowserWarning from '../outdatedBrowserWarning';

interface Props {
  title: string;
  description?: string;
}

const Page: FC<Props> = ({ title, description, children }) => {
  const [cookies, setCookie] = useCookies();
  const [basketExpiring, setBasketExpiring] = useState(false);
  const { asPath: currentRoute = '' } = useRouter();
  const { loggedIn, user, actions, acceptedLatestConditions } = useContext(
    AuthContext
  );
  const { filters } = useContext(SearchContext);
  const { refreshAuthToken } = actions;
  const { items } = useContext(BasketContext);
  const [acceptConditionsModalOpen, setAcceptConditionsModalOpen] = useState(
    !acceptedLatestConditions
  );

  const apiRequest = useApiRequest();

  const [pageId = 'home'] = currentRoute.split('/').filter(x => !!x);

  useEffect(() => {
    const expiryMs = cookies.basketExpiry
      ? moment(cookies.basketExpiry.expiresAt).subtract(5, 'minutes') - moment()
      : 0;

    setBasketExpiring(loggedIn && items.length > 0 && expiryMs <= 0);

    const timer =
      loggedIn && items.length > 0 && expiryMs > 0
        ? setTimeout(() => setBasketExpiring(true), expiryMs)
        : null;

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [loggedIn, items.length, cookies.basketExpiry]);

  const expiryModalOpen =
    basketExpiring &&
    !!cookies.basketExpiry &&
    (!cookies.basketExpiry.dismissedAt ||
      new Date(cookies.basketExpiry.expiresAt) >
        new Date(cookies.basketExpiry.dismissedAt));

  const closeExpiryModal = () =>
    setCookie(
      'basketExpiry',
      {
        ...cookies.basketExpiry,
        dismissedAt: cookies.basketExpiry.expiresAt,
      },
      { path: '/', secure: true }
    );

  const closeAcceptConditionsModal = () => {
    apiRequest.put(`/v1/users/current/accept_conditions`).then(() => {
      refreshAuthToken();
      setAcceptConditionsModalOpen(false);
    });
  };

  const showAcceptConditionsModal =
    loggedIn &&
    !(
      acceptedLatestConditions ||
      pageExemptFromConditionAcceptance(currentRoute)
    );

  const baseData = {
    event: 'ospace.universal',
    isLoggedIn: loggedIn,
    bookingDate: filters.date,
    searchLocation: filters.location,
    roomTypes: filters.roomTypes,
  };

  const userData = loggedIn
    ? {
        userId: user.id,
        clientId: user.clientId,
        clientName: user.clientName,
        userType: user.role,
      }
    : null;

  useDataLayer(loggedIn ? { ...baseData, ...userData } : baseData);

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, minimum-scale=1.0, user-scalable=yes"
        />
        <meta name="description" content={description} key="description" />
      </Head>
      <NoScriptWarning />
      <OutdatedBrowserWarning />
      <div id={pageId} className="page-content">
        {showAcceptConditionsModal && (
          <AcceptConditionsModal
            isOpen={acceptConditionsModalOpen}
            onClose={closeAcceptConditionsModal}
          />
        )}

        {!showAcceptConditionsModal && (
          <BasketExpiryModal
            isOpen={expiryModalOpen}
            onClose={closeExpiryModal}
          />
        )}
        <HeaderMobileNav />
        <HeaderNav pageId={pageId} />
        {children}
        <Footer />
      </div>
    </>
  );
};

export default Page;
