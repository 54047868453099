import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { throttle } from 'lodash';
import PropTypes from 'prop-types';

import Link from 'next/link';
import { useRouter } from 'next/router';

import { i18n as I18n } from 'utils/i18n';
import useIsMobile from '../../hooks/useIsMobile';

import { AuthContext } from '../../state/auth';
import { BasketContext } from '../../state/basket/index.tsx';

import QuickSearch from '../../containers/quickSearch/index.tsx';

const HeaderNav = ({ pageId }) => {
  const { actions, loggedIn } = useContext(AuthContext);
  const { items } = useContext(BasketContext);

  const router = useRouter();
  const isMobileView = useIsMobile();

  const [stickyNavVisible, toggleStickyNavVisibility] = useState(false);
  const [quickSearchVisible, toggleQuickSearchVisibility] = useState(false);

  const classes = classNames({
    header: true,
    'header--absolute': !stickyNavVisible,
    'header--sticky': stickyNavVisible,
  });

  useEffect(() => {
    const pageUrls = [
      'home',
      'book-consulting-rooms',
      'book-minor-operations-rooms',
      'book-treatment-rooms',
      'book-examination-rooms',
      'book-counselling-rooms',
      'book-group-rooms',
      'book-meeting-rooms',
      'book-office-rooms',
    ];

    const verticalBreakpoint = 515;

    const handleScroll = () => {
      if (!isMobileView) {
        if (window.pageYOffset > verticalBreakpoint) {
          toggleStickyNavVisibility(true);

          if (pageUrls.includes(pageId)) {
            toggleQuickSearchVisibility(true);
          }
        } else {
          toggleStickyNavVisibility(false);

          if (pageUrls.includes(pageId)) {
            toggleQuickSearchVisibility(false);
          }
        }
      }
    };

    const throttledHandleScroll = throttle(handleScroll, 500);

    if (pageUrls.includes(pageId)) {
      window.addEventListener('scroll', throttledHandleScroll);
    } else {
      toggleStickyNavVisibility(true);
      toggleQuickSearchVisibility(true);
    }

    return () => window.removeEventListener('scroll', throttledHandleScroll);
  }, [isMobileView, pageId]);

  const SignedInNav = ({ basketItemsCount, handleLogout }) => (
    <>
      <li className="header__nav-item">
        <div className="dropdown" role="button">
          {I18n.t('account.title')}
          <img src="/images/icons/homepage/navigation/chevron.svg" alt="" />
          <div className="dropdown__content">
            <ul>
              <li>
                <Link href={I18n.t('navigation.bookings.url')}>
                  <a>{I18n.t('navigation.bookings.text')}</a>
                </Link>
              </li>
              <li>
                <Link href={I18n.t('navigation.accounts.url')}>
                  <a>{I18n.t('navigation.accounts.text')}</a>
                </Link>
              </li>
              <li>
                <a data-testid="logoutBtn" onClick={handleLogout}>
                  {I18n.t('navigation.sign_out.text')}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
      <li className="header__nav-item basket-link">
        <Link href={I18n.t('navigation.basket.url')}>
          <a>{I18n.t('navigation.basket.text')}</a>
        </Link>
        <span className="basket-link__count">{basketItemsCount}</span>
      </li>
    </>
  );

  SignedInNav.propTypes = {
    basketItemsCount: PropTypes.number,
    handleLogout: PropTypes.func.isRequired,
  };

  SignedInNav.defaultProps = {
    basketItemsCount: 0,
  };

  const SignedOutNav = () => (
    <>
      <li className="header__nav-item">
        <Link href={I18n.t('navigation.sign_in.url')} passHref>
          <a
            onClick={() => {
              if (router.pathname !== I18n.t('navigation.sign_in.url'))
                actions.setReturnUrl(router.asPath);
            }}
            className="button button--outline button--outline-secondary button--secondary-text"
            data-cy="signInButton"
          >
            {I18n.t('navigation.sign_in.text')}
          </a>
        </Link>
      </li>
      <li className="header__nav-item">
        <Link href={I18n.t('navigation.register.url')}>
          <a className="button button--primary button--white-text" href="">
            {I18n.t('navigation.register.text')}
          </a>
        </Link>
      </li>
    </>
  );

  return (
    <div className={classes}>
      <div className="header__nav" data-cy="navbar">
        <Link href="/">
          <a>
            <div className="header__logo header__logo--absolute">
              <img src="/images/openspace-logo.svg" alt="NHS Open Space" />
            </div>
            <div className="header__logo header__logo--sticky">
              <img src="/images/openspace-logo.svg" alt="NHS Open Space" />
            </div>
          </a>
        </Link>

        {quickSearchVisible && <QuickSearch />}

        <ul className="header__nav-list">
          <li className="header__nav-item find-rooms">
            <Link href={I18n.t('navigation.search.url')}>
              <a>{I18n.t('navigation.search.text')}</a>
            </Link>
          </li>
          <li className="header__nav-item find-rooms">
            <Link href={I18n.t('navigation.properties.url')}>
              <a>{I18n.t('navigation.properties.text')}</a>
            </Link>
          </li>
        </ul>

        <ul className="header__nav-list header__nav-list--pull">
          <li className="header__nav-item properties">
            <Link href={I18n.t('navigation.properties.url')}>
              <a>{I18n.t('navigation.properties.text')}</a>
            </Link>
          </li>
          <li className="header__nav-item">
            <Link href={I18n.t('navigation.help.url')}>
              <a>{I18n.t('navigation.help.text')}</a>
            </Link>
          </li>
          {loggedIn ? (
            <SignedInNav
              basketItemsCount={items.length}
              handleLogout={() => actions.signOut()}
            />
          ) : (
            <SignedOutNav />
          )}
        </ul>
      </div>
    </div>
  );
};

HeaderNav.propTypes = {
  pageId: PropTypes.string.isRequired,
};

export default HeaderNav;
