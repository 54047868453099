import { useState, useEffect, useRef, useCallback } from 'react';

interface AsyncResult<T> {
  loading: boolean;
  error: boolean;
  refresh: () => Promise<T | undefined>;
}

const useAsync = <T = void>(
  fn: () => Promise<T>,
  enabled: boolean = true
): AsyncResult<T> => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const promiseRef = useRef<Promise<T | undefined>>(null);

  const refresh = useCallback(async (): Promise<T | undefined> => {
    if (promiseRef.current) await promiseRef.current;

    const run = async (): Promise<T | undefined> => {
      try {
        setLoading(true);

        const result = await fn();

        setLoading(false);
        setError(false);

        promiseRef.current = null;
        return result;
      } catch (e) {
        setError(true);

        promiseRef.current = null;
        return undefined;
      }
    };

    if (enabled) {
      promiseRef.current = run();
      return promiseRef.current;
    }

    return fn();
  }, [fn, enabled]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return { loading, error, refresh };
};

export default useAsync;
