import Axios from 'axios';
import getConfig from 'next/config';

export interface LatLng {
  lat: number;
  lng: number;
}

export interface Geolocation {
  location: LatLng;
  accuracy: number;
}

interface CellTower {
  cellId?: number;
  newRadioCellId?: number;
  locationAreaCode?: number;
  mobileCountryCode?: number;
  mobileNetworkCode: number;
}

interface WifiAccessPoint {
  macAddress: string;
  signalStrength?: number;
  age?: number;
  channel?: number;
  signalToNoiseRatio?: number;
}

export interface Params {
  homeMobileCountryCode?: number;
  homeMobileNetworkCode?: number;
  radioType?: 'gsm' | 'cdma' | 'wcdma' | 'lte' | 'nr';
  carrier?: string;
  considerIp?: boolean;
  cellTowers?: CellTower[];
  wifiAccessPoints?: WifiAccessPoint[];
}

export interface Options {
  apiKey?: string;
}

const {
  publicRuntimeConfig: { googleMapsApiKey },
} = getConfig();

export const getGoogleGeolocation = (
  params?: Params = {},
  { apiKey: key = googleMapsApiKey }: Options = {}
): Promise<Geolocation> =>
  (Axios.post('https://www.googleapis.com/geolocation/v1/geolocate', params, {
    params: { key },
  }).then(({ data }) => data) as Promise<unknown>) as Promise<Geolocation>;

// eslint-disable-next-line no-undef
const getCurrentPosition = (options?: PositionOptions) =>
  new Promise<LatLng>((resolve, reject) =>
    navigator.geolocation
      ? navigator.geolocation.getCurrentPosition(
          // eslint-disable-next-line no-undef
          ({ coords: { latitude, longitude } = {} }: GeolocationPosition) =>
            resolve({ lat: latitude, lng: longitude }),
          reject,
          options
        )
      : reject(new Error("Browser doesn't support geolocation"))
  );

export default getCurrentPosition;
