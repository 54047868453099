import React, { useState } from 'react';
import Link from 'next/link';
import Modal from 'react-modal';

import { i18n as I18n } from 'utils/i18n';

import Checkbox from '../fields/checkbox';

interface Props {
  isOpen: boolean;
  onClose: Function;
}

const AcceptConditionsModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const [privacyPolicyAgreed, togglePrivacyPolicyAgreement] = useState(false);
  const [termsAgreed, toggleTermsAgreement] = useState(false);

  const agreementLabelContent = (text, href) => (
    <>
      {I18n.t('registration.service_details.i_have_read_and_agree_to')}
      &nbsp;
      <Link href={href}>
        <a target="_blank">{text}</a>
      </Link>
    </>
  );

  const handleConfirm = () => {
    onClose();
  };

  const conditionsAccepted = termsAgreed && privacyPolicyAgreed;

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Accept Conditions Modal"
      className="modal modal--small accept-conditions-modal"
      overlayClassName="modal__overlay"
      shouldCloseOnOverlayClick={false}
    >
      <div className="accept-conditions-modal__header">
        <h4 className="accept-conditions-modal-title">
          <i className="fas fa-exclamation-triangle" />
          {I18n.t('accept_conditions_modal.title')}
        </h4>
      </div>
      <div className="accept-conditions-modal__inner">
        <h2>{I18n.t('accept_conditions_modal.subtitle')}</h2>
        <p className="accept-conditions-modal__inner-intro-text">
          {I18n.t('accept_conditions_modal.body')}
        </p>
        <p>
          <Checkbox
            label={agreementLabelContent(
              I18n.t('registration.service_details.fields.agree_privacy.label'),
              I18n.t('navigation.privacy_policy.url')
            )}
            name="agreePrivacyPolicy"
            id="agreePrivacyPolicy"
            checked={privacyPolicyAgreed}
            onChange={() => {
              togglePrivacyPolicyAgreement(!privacyPolicyAgreed);
            }}
          />
          <Checkbox
            label={agreementLabelContent(
              I18n.t('registration.service_details.fields.agree_terms.label'),
              I18n.t('navigation.terms_and_conditions.url')
            )}
            name="agreeTerms"
            id="agreeTerms"
            checked={termsAgreed}
            onChange={() => {
              toggleTermsAgreement(!termsAgreed);
            }}
          />
        </p>

        <p>
          <button
            type="button"
            onClick={handleConfirm}
            className="button button--secondary"
            disabled={!conditionsAccepted}
            data-testid="acceptConditionsButton"
          >
            {I18n.t('accept_conditions_modal.confirm')}
          </button>
        </p>
      </div>
    </Modal>
  );
};

AcceptConditionsModal.defaultProps = {
  isOpen: false,
  onClose: () => null,
};

export default AcceptConditionsModal;
