import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { throttle } from 'lodash';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { i18n as I18n } from 'utils/i18n';

// context
import { AuthContext } from '../../state/auth';
import { BasketContext } from '../../state/basket/index.tsx';
import { MobileHeaderContext } from '../../state/mobileHeader';

// components
import QuickSearch from '../../containers/quickSearch/index.tsx';

const HeaderMobileNav = () => {
  const { actions, loggedIn: isLoggedIn } = useContext(AuthContext);
  const { items } = useContext(BasketContext);
  const {
    stickyNavVisible,
    toggleStickyNavVisibility,
    menuOpen,
    setMenuOpen,
    searchOpen,
    setSearchOpen,
    roomTypesOpen,
  } = useContext(MobileHeaderContext);

  const router = useRouter();

  const [loggedIn, setLoggedIn] = useState(false);

  const headerClasses = classNames({
    'mobile-header': true,
    'mobile-header--sticky': stickyNavVisible,
    'mobile-header--menu-closed': !menuOpen,
    'mobile-header--menu-open': !!menuOpen,
    'mobile-header--search-closed': !searchOpen,
    'mobile-header--search-open': !!searchOpen,
    'mobile-header--room-types-closed': !roomTypesOpen,
    'mobile-header--room-types-open': !!roomTypesOpen,
  });

  const searchClasses = classNames({
    'mobile-room-search': true,
    'mobile-room-search--closed': !searchOpen,
    'mobile-room-search--open': !!searchOpen,
  });

  useEffect(() => {
    const displayStickyNavAt = 0;
    const handleScroll = () => {
      toggleStickyNavVisibility(window.pageYOffset > displayStickyNavAt);
    };
    const throttledHandleScroll = throttle(handleScroll, 100);
    window.addEventListener('scroll', throttledHandleScroll);

    return () => window.removeEventListener('scroll', throttledHandleScroll);
  }, [toggleStickyNavVisibility]);

  const toggleMenuOpen = () => {
    setMenuOpen(!menuOpen);
    setSearchOpen(false);
  };

  const toggleSearchOpen = () => {
    setSearchOpen(!searchOpen);
    setMenuOpen(false);
  };

  const closeAll = () => {
    setMenuOpen(false);
    setSearchOpen(false);
  };

  useEffect(() => {
    if (isLoggedIn) {
      setLoggedIn(true);
    }
  }, [isLoggedIn, setLoggedIn]);

  return (
    <div className={headerClasses}>
      <div className="mobile-header__nav" data-cy="mobileHeader">
        <div className="mobile-header__logo mobile-header__logo--non-sticky">
          <Link href="/">
            <a>
              <img src="/images/openspace-logo.svg" alt="NHS Open Space" />
            </a>
          </Link>
        </div>
        <div className="mobile-header__logo mobile-header__logo--sticky">
          <Link href="/">
            <a>
              <img src="/images/openspace-logo.svg" alt="NHS Open Space" />
            </a>
          </Link>
        </div>

        {loggedIn && (
          <div className="mobile-header__basket" onClick={closeAll}>
            <Link href={I18n.t('navigation.basket.url')}>
              <img
                src="/images/icons/homepage/navigation/cart.svg"
                alt={I18n.t('navigation.basket.text')}
                data-cy="basketLink"
              />
            </Link>
            {items.length > 0 && (
              <span
                className="mobile-header__basket--count"
                data-cy="basketCount"
              >
                {items.length}
              </span>
            )}
          </div>
        )}

        <div className="mobile-header__search-rooms" onClick={toggleSearchOpen}>
          <img
            src="/images/icons/homepage/navigation/search.svg"
            alt="Magnifying glass"
          />
        </div>

        <div
          className="mobile-header__toggle-menu"
          onClick={toggleMenuOpen}
          data-testid="toggleMenu"
        >
          <img
            src="/images/icons/homepage/navigation/hamburger.svg"
            id="nav-open-icon"
            alt="Navigation open"
          />
          <img
            src="/images/icons/homepage/navigation/close.svg"
            id="nav-close-icon"
            alt="Navigation close"
          />
        </div>
      </div>

      <nav className="mobile-menu">
        <ul className="mobile-menu__list" id="mobile-nav-list">
          <li className="mobile-menu__item">
            <Link href={I18n.t('navigation.search.url')}>
              <a onClick={closeAll}>{I18n.t('navigation.search.text')}</a>
            </Link>
          </li>
          <li className="mobile-menu__item">
            <Link href={I18n.t('navigation.properties.url')}>
              <a onClick={closeAll}>{I18n.t('navigation.properties.text')}</a>
            </Link>
          </li>
          <li className="mobile-menu__item">
            <Link href={I18n.t('navigation.help.url')}>
              <a onClick={closeAll}>{I18n.t('navigation.help.text')}</a>
            </Link>
          </li>

          {loggedIn && (
            <>
              <li className="mobile-menu__item">
                <Link href={I18n.t('navigation.accounts.url')}>
                  <a onClick={closeAll}>{I18n.t('navigation.accounts.text')}</a>
                </Link>
              </li>
              <li className="mobile-menu__item">
                <Link href="#">
                  <a
                    onClick={() => {
                      closeAll();
                      actions.signOut();
                    }}
                    className="button button--block button--outline button--outline-secondary button--secondary-text"
                  >
                    {I18n.t('navigation.sign_out.text')}
                  </a>
                </Link>
              </li>
            </>
          )}

          {!loggedIn && (
            <>
              <li className="mobile-menu__item">
                <Link href={I18n.t('navigation.sign_in.url')}>
                  <a
                    onClick={() => {
                      closeAll();
                      if (router.pathname !== I18n.t('navigation.sign_in.url'))
                        actions.setReturnUrl(router.asPath);
                    }}
                    className="button button--primary button--block button--outline button--outline-secondary button--secondary-text"
                  >
                    {I18n.t('navigation.sign_in.text')}
                  </a>
                </Link>
              </li>
              <li className="mobile-menu__item">
                <Link href={I18n.t('navigation.register.url')}>
                  <a
                    className="button button--primary button--block button--white-text"
                    onClick={closeAll}
                  >
                    {I18n.t('navigation.register.text')}
                  </a>
                </Link>
              </li>
            </>
          )}
        </ul>
      </nav>

      <div className={searchClasses} id="#mobile-search">
        <h1 className="mobile-room-search__heading">
          {I18n.t('quick_search.heading')}
        </h1>
        <QuickSearch onSubmit={closeAll} />
      </div>
    </div>
  );
};

export default HeaderMobileNav;
