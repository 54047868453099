import { FC } from 'react';
import { useRouter } from 'next/router';
import { i18n as I18n } from 'utils/i18n';

import ModalDialog from '../modal';

interface Props {
  isOpen: boolean;
  onClose: () => null;
}

const BasketExpiryModal: FC<Props> = ({ isOpen = false, onClose }) => {
  const router = useRouter();

  return (
    <ModalDialog
      isOpen={isOpen}
      onClose={onClose}
      headerLabel={I18n.t('basket.expiry_warning_modal.header')}
      size="xsmall"
      primaryAction={
        <button
          onClick={() => {
            onClose();
            router.push(I18n.t('navigation.basket.url'));
          }}
          className="button button--secondary"
          data-testid="completeBooking"
        >
          {I18n.t('basket.expiry_warning_modal.complete_booking')}
        </button>
      }
      secondaryAction={
        <button
          onClick={onClose}
          className="button button--tertiary"
          data-testid="continueBrowsing"
        >
          {I18n.t('basket.expiry_warning_modal.continue_browsing')}
        </button>
      }
      showCloseIcon
    >
      <p data-testid="removalText">
        {I18n.t('basket.expiry_warning_modal.body')}
      </p>
    </ModalDialog>
  );
};

export default BasketExpiryModal;
