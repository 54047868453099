import { FC, ComponentProps } from 'react';
import {
  useFormik,
  FormikProvider,
  Form,
  FormikConfig,
  FormikFormProps,
} from 'formik';
import classNames from 'classnames';

import { i18n as I18n } from 'utils/i18n';
import { getValidator } from 'validators/venueSearch';
import { LatLng } from 'utils/geolocation';

import Field from 'components/fields/field';
import RoomTypesField from 'components/fields/roomTypeField';
import LocationField from 'components/fields/locationField';

interface Values {
  location: string;
  distance?: string;
  geolocation?: LatLng;
  roomTypes?: string[];
}

type OnSubmit = FormikConfig<Values>['onSubmit'];
type GetCurrentPosition = ComponentProps<
  typeof LocationField
>['getCurrentPosition'];

interface Props extends FormikFormProps {
  initialValues?: Values;
  onSubmit?: OnSubmit;
  showRoomTypes?: boolean;
  locationPlaceholder?: string;
  getCurrentPosition?: GetCurrentPosition;
}

const emptyValues: Values = {
  location: '',
  distance: '',
  geolocation: null,
  roomTypes: [],
};

const QuickSearchForm: FC<Props> = ({
  className: classNameProp,
  initialValues = emptyValues,
  onSubmit,
  showRoomTypes = true,
  locationPlaceholder,
  getCurrentPosition,
  ...props
}) => {
  const className = classNames(
    'form',
    'form--inline',
    'form--quicksearch',
    classNameProp
  );

  const formik = useFormik<Values>({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validate: getValidator(),
    validateOnBlur: false,
    validateOnChange: true,
    validateOnMount: false,
  });

  const { values, errors, setValues, setFieldValue } = formik;

  const setGeolocation = geolocation =>
    geolocation
      ? setValues({
          ...values,
          location: '',
          distance: '10',
          geolocation,
        })
      : setFieldValue('geolocation', null);

  const rejectGeolocation = () =>
    setValues({
      ...values,
      location: '',
      distance: '',
      geolocation: null,
    });

  return (
    <FormikProvider value={formik}>
      <div className="quicksearch">
        <Form className={className} {...props}>
          {!!showRoomTypes && (
            <div className="form__field">
              <Field
                as={RoomTypesField}
                name="roomTypes"
                setValueProp="setValue"
                className="field--no-margin"
              />
            </div>
          )}
          <div className="form__field">
            <Field
              as={LocationField}
              name="location"
              setValueProp="setValue"
              className="field--no-margin"
              placeholder={locationPlaceholder}
              usingGeolocation={!!values.geolocation}
              setGeolocation={setGeolocation}
              onRejectGeolocation={rejectGeolocation}
              getCurrentPosition={getCurrentPosition}
            />
          </div>
          <button className="button button--secondary" type="submit">
            {I18n.t('quick_search.button_text')}
          </button>
        </Form>
        {'location' in errors && <div className="error">{errors.location}</div>}
      </div>
    </FormikProvider>
  );
};

export default QuickSearchForm;
