import {
  FC,
  HTMLProps,
  ChangeEventHandler,
  FocusEventHandler,
  useState,
  useMemo,
  useEffect,
} from 'react';
import classNames from 'classnames';

import { i18n as I18n } from 'utils/i18n';

import useLocations from 'hooks/useLocations';

import defaultGetCurrentPosition, { LatLng } from 'utils/geolocation';

import Location from './location';

export type SearchLocation = {
  name: string;
  city: string;
  term: string;
  count?: number;
};

interface Props extends HTMLProps<HTMLInputElement> {
  setValue: (value: string) => void;
  getCurrentPosition?: typeof defaultGetCurrentPosition;
  usingGeolocation?: boolean;
  setGeolocation?: (geolocation: LatLng) => void;
  onRejectGeolocation?: () => void;
  geolocationLabel?: string;
}

const noOp = () => null;

const LocationField: FC<Props> = ({
  value = '',
  setValue,
  getCurrentPosition = defaultGetCurrentPosition,
  usingGeolocation = false,
  setGeolocation,
  onRejectGeolocation = noOp,
  geolocationLabel = I18n.t('shared.current_location'),
  placeholder = I18n.t('shared.location_placeholder'),
  className: classNameProp,
  onFocus: onFocusProp = noOp,
  ...props
}) => {
  const [locationsOpen, setLocationsOpen] = useState<boolean>(false);
  const { data: locations } = useLocations(value);

  const locationsAvailable = useMemo(
    () => Object.values(locations).flat().length > 0,
    [locations]
  );

  const className = classNames('field', classNameProp);

  useEffect(() => {
    const handleClick = () => {
      setLocationsOpen(false);
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const onClick = (location: string) => {
    setLocationsOpen(false);
    setValue(location);
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: { value: location },
  }) => {
    setLocationsOpen(true);
    setValue(location);
  };

  const onFocus: FocusEventHandler<HTMLInputElement> = e => {
    if (usingGeolocation && setGeolocation) setGeolocation(null);
    return onFocusProp(e);
  };

  const geolocate = () =>
    getCurrentPosition().then(setGeolocation).catch(onRejectGeolocation);

  const icon =
    setGeolocation && !usingGeolocation ? (
      <button
        type="button"
        title={I18n.t('location_field.geolocation_button_title')}
        onClick={geolocate}
      >
        <i className="location__icon fa fa-location-arrow" />
      </button>
    ) : (
      <button
        type="button"
        title={
          setGeolocation
            ? I18n.t('location_field.location_button_title')
            : undefined
        }
        onClick={() => (setGeolocation ? setGeolocation(null) : null)}
      >
        <i className="location__icon fa fa-search" />
      </button>
    );

  return (
    <div className={className} data-cy="locationField">
      <div className="location">
        <div className="location__inner">
          <input
            name="location"
            className="location__input"
            type="search"
            placeholder={placeholder}
            value={usingGeolocation ? geolocationLabel : value}
            onFocus={onFocus}
            autoComplete="off"
            {...props}
            onChange={usingGeolocation ? undefined : onChange}
            readOnly={usingGeolocation}
          />
          {icon}
        </div>
        {locationsAvailable && locationsOpen && (
          <ul className="location__search-results" role="listbox">
            {locations.hits.map((location, index) => (
              <li
                className="location__search-result"
                key={`${location.name}-${location.city}-${index}`}
              >
                <Location
                  name={location.term}
                  city={location.city}
                  onClick={() => onClick(location.name)}
                />
              </li>
            ))}
            {locations.aggregates.map(({ buckets = [] }) =>
              [...buckets]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((location, index) => (
                  <li
                    className="location__search-result"
                    key={`${location.name}-${location.city}-${index}`}
                  >
                    <Location
                      name={location.name}
                      city={location.city}
                      count={location.count}
                      onClick={() =>
                        onClick(`${location.name}, ${location.city}`)
                      }
                    />
                  </li>
                ))
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default LocationField;
