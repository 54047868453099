import PropTypes from 'prop-types';
import classNames from 'classnames';

const Badge = ({ count, success }) => {
  const classes = classNames({
    badge: true,
    'badge--success': !!success,
  });

  return <span className={classes}>{count}</span>;
};

Badge.propTypes = {
  count: PropTypes.number,
  success: PropTypes.bool,
};

Badge.defaultProps = {
  count: 0,
  success: true,
};

export default Badge;
