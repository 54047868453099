import { FC, useState, useEffect } from 'react';
import { i18n as I18n } from 'utils/i18n';
import { isBelowIE11 } from 'constants/browser';

import { detect } from 'detect-browser';

interface Props {
  className?: string;
  message?: string;
}

const OutdatedBrowserWarning: FC<Props> = ({
  className = 'outdated-browser-warning',
  message = I18n.t('outdated_browser_warning.message'),
}) => {
  const [browser, setBrowser] = useState(undefined);

  useEffect(() => {
    setBrowser(detect());
  }, []);

  if (browser && isBelowIE11(browser)) {
    return <div className={className}>{message}</div>;
  }
  return null;
};

export default OutdatedBrowserWarning;
