import { FC, ChangeEventHandler } from 'react';

import classNames from 'classnames';
import { ReactSVG } from 'react-svg';

interface Props {
  label: string;
  id: string;
  name: string;
  clinical?: boolean;
  category: string;
  icon: string;
  checked?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const renderIcon = (icon: string): string => {
  switch (icon) {
    case 'treatment':
      return 'treatment.svg';

    case 'examination':
      return 'examination.svg';

    case 'consulting':
      return 'consulting.svg';

    case 'counselling':
      return 'counselling.svg';

    case 'groupRoom':
      return 'group-room.svg';

    case 'meetings':
      return 'meeting.svg';

    case 'offices':
      return 'office.svg';

    default:
      return 'minor-operations.svg';
  }
};

const RoomType: FC<Props> = ({
  label,
  id,
  name,
  clinical = false,
  category,
  icon,
  checked = false,
  onChange,
}) => {
  const labelClasses = classNames('room-types__option', {
    'room-types__option--clinical': !!clinical,
    'room-types__option--active': !!checked,
  });

  return (
    <>
      <input
        data-testid="roomTypeInput"
        className="room-types__input"
        type="checkbox"
        name={name}
        id={id}
        checked={checked}
        onChange={onChange}
        value={category}
      />
      <label
        className={labelClasses}
        htmlFor={id}
        data-testid="roomTypeOption"
        data-cy="room-type"
      >
        <ReactSVG
          className="room-types__icon"
          alt={id}
          src={`/images/icons/homepage/room-types/${renderIcon(icon)}`}
        />
        <span className="room-types__label">{label}</span>
      </label>
    </>
  );
};

export default RoomType;
