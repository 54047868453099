import { FC, Fragment } from 'react';
import { i18n as I18n } from 'utils/i18n';

interface Props {
  wrapper?: boolean;
  className?: string;
  message?: string;
}

const NoScriptWarning: FC<Props> = ({
  wrapper = true,
  className = 'no-script-warning',
  message = I18n.t('noscript_warning'),
}) => {
  const Wrapper = wrapper ? 'noscript' : Fragment;

  return (
    <Wrapper>
      <div className={className}>{message}</div>
    </Wrapper>
  );
};

export default NoScriptWarning;
