import { useRef, useEffect } from 'react';

const useOutsideClick = (ref, callback, ignoreClasses = []) => {
  const ignoreClassesJson = JSON.stringify(ignoreClasses);

  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const handleClick = e => {
      const isIgnoreClass = JSON.parse(ignoreClassesJson).some(ignoreClass => {
        return e.target.classList.contains(ignoreClass);
      });

      if (isIgnoreClass) {
        return null;
      }

      if (ref.current && !ref.current.contains(e.target)) {
        callbackRef.current();
      }

      return null;
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [ref, ignoreClassesJson]);
};

export default useOutsideClick;
